<template>
  <div class="model-view-page" v-loading="loading">
  

    <div class="model-detail-modal" v-if="hasAccess">
      <div class="info-wrapper">
        <div class="c-wrapper basic-wrapper">
          <ul>
            <li>
              <p class="name">作品名称</p>
              <el-tooltip
                class="item"
                effect="dark"
                :content="dataInfo.name"
                placement="top"
              >
                <p class="value ellipsis">
                  {{ dataInfo.name }}
                </p>
              </el-tooltip>
            </li>
            <li>
              <p class="name">作品大小</p>
              <p class="value">
                <span v-if="dataInfo.size < 1024">{{ dataInfo.size }}KB</span>
                <span v-else>{{ (dataInfo.size / 1024).toFixed(2) }}MB</span>
              </p>
            </li>
            <li>
              <p class="name">作品类型</p>
              <p class="value">{{ dataInfo.format || "--" }}</p>
            </li>
            <li>
              <p class="name">作品集</p>
              <p
                class="value pointer"
                @click="
                  $router.push(`/community/collection/${dataInfo.directoryId}`)
                "
              >
                {{ dataInfo.directoryName }}
              </p>
            </li>
            <li>
              <p class="name">作品时间</p>
              <p class="value">{{ dataInfo.createTime }}</p>
            </li>
          </ul>
        </div>
        <div class="c-wrapper intercept-wrapper">
          <p class="name">封面</p>
          <div class="cover reload-qty">
            <div class="reload-mask-qty" v-if="dataInfo.userId === userId">
              <div class="reload-container-qty" @click="onClickSnapshot">
                <div class="icon-qty">
                  <img src="../../assets/images/icon/reload_icon.png" alt="" />
                </div>
                <div class="name-qty">更新封面</div>
              </div>
            </div>

            <img
              v-if="dataInfo.obsThumbUrl"
              :src="dataInfo.obsThumbUrl"
              style="width: 100%; height: auto"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/coverdefault.png"
              style="width: 100%; height: auto"
              alt=""
            />
          </div>
        </div>
        <div class="c-wrapper user-wrapper">
          <div class="name">设计师信息</div>
          <div class="value">
            <el-avatar :size="50" :src="photo || circleUrl"></el-avatar>
            <span class="user-name">{{
              $lodash.phoneNuberConvert(dataInfo.nickname)
            }}</span>
          </div>
        </div>

        <div class="download-wrapper" v-if="!(dataInfo.allowDownload === 0)">
          <el-button @click.stop="handleDownLoad" class="download-btn"
            >下载作品</el-button
          >
          <el-button
            class="screenshot-btn"
            :loading="saveWorkspaceLoading"
            @click.stop="handleSaveInWorkspace"
          >
            保存到工作台
          </el-button>
        </div>
      </div>
    </div>
    <!-- 无权限提示模块 -->
    <div class="w access-wrapper" v-if="!hasAccess">
      <img src="../../assets/images/icon/icon_user.png" alt="" />
      <h2>抱歉，您无权查看文件</h2>
      <p>您要浏览的文件处于私密状态或者已被删除</p>
      <div class="btn-box">
        <el-button
          @click="$router.push('/community')"
          type="primary"
          size="small"
          >返回社区</el-button
        >
      </div>
    </div>
      <!-- 引入iview页面 -->
    <iframe
      v-if="hasAccess"
      :src="iViewUrl"
      ref="iViewFrame"
      width="100%"
      height="100%"
      frameborder="no"
      allowfullscreen="true"
    ></iframe>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { downloadModelV2 } from "@/util/model";
export default {
  data() {
    return {
      canvas: null,
      studio: null,
      SUCCESS_CODE: 0,
      id: "",
      sceneId: "",
      options: {
        dracoDecoderConfig: {
          decoderFilePath: "/realibox/draco/draco_decoder.js",
          wasmWrapperFilePath: "/realibox/draco/draco_wasm_wrapper.js",
          wasmFilePath: "/realibox/draco/draco_decoder.wasm",
        },
      },
      dataInfo: {},
      photo: "",
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      hasAccess: true,
      loading: false,
      iViewUrl: "",
      isSnapshot: false,
      saveWorkspaceLoading: false,
      userId: "",
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getImageInfo(this.id);
    this.viewStatistics(this.id);
    window.addEventListener("message", this.onTakeSnapshot);
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.userId = userInfo.id;
  },
  mounted() {},
  methods: {
    /**
     * 保存到工作台
     */
    async handleSaveInWorkspace() {
      const params = { rid: this.id };
      this.saveWorkspaceLoading = true;
      try {
        const res = await this.$http.post("/resource-info/collect", null, {
          params,
        });
        if (res.code === this.SUCCESS_CODE) {
          this.$message.success("操作成功！");
        } else {
          this.$message.error(res.msg);
        }
      } catch {
        this.$message.error("操作失败！");
      } finally {
        this.saveWorkspaceLoading = false;
      }
    },
    viewStatistics(id) {
      this.$http.put("/resource-info/view/" + id).then((res) => {
        if (res.code == this.SUCCESS_CODE) {
          console.log("view success");
        }
      });
    },
    // 获取图片详情
    getImageInfo(id) {
      this.loading = true;
      this.$http
        .get("/resource-info/" + id)
        .then((res) => {
          this.hasAccess = false;
          this.dataInfo = {};
          if (res.code == this.SUCCESS_CODE && res.data) {
            let data = res.data.resourceInfo;
            //访问权限判断
            this.hasAccess = false;
            if (data.isPublic) {
              this.hasAccess = true;
            } else {
              let userInfoStr = localStorage.getItem("userInfo");
              let userInfo = userInfoStr && JSON.parse(userInfoStr);
              if (userInfo && userInfo.id == data.userId) {
                this.hasAccess = true;
              }
            }
            console.log("qty", res.data);
            this.dataInfo = res.data.resourceInfo;
            this.photo = res.data.photo;
            // 判断是否是模型
            if (data.type == 1 && data.objectId) {
              this.iViewUrl =
                "https://hub.realibox.com/viewer/model/" +
                process.env.VUE_APP_PROJECT_ID +
                "/" +
                data.objectId +
                `/share?version=1${data.format==='skp'?'&show_white_effect=true':''}`;
            }
          } else {
            this.hasAccess = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.hasAccess = false;
          this.dataInfo = {};
        });
    },
    onClickSnapshot() {
      this.$refs.iViewFrame.contentWindow.postMessage(
        {
          type: "reaibox:viewer:takeSnapshot:start",
        },
        "*"
      );
      this.isSnapshot = true;
    },
    onTakeSnapshot(e) {
      if (e.data && e.data.type === "realibox:viewer:takeSnapshot:success") {
        console.log(e);
        this.isSnapshot = false;
        let base64Cover = e.data.img; //截图返回base64图片
        let filename = uuidv4();
        let blob = this.dataURLtoBlob(base64Cover, filename);
        this.obsUpload(blob, filename, null);
      }
    },
    // obs上传图片
    obsUpload(file, key, callback) {
      console.log(456);
      let self = this;
      // this.uploadLoading = true;
      var ak = self.$OBS_AK;
      var sk = self.$OBS_SK;
      var server = self.$OBS_SERVER;
      var bucket = self.$OBS_BUCKET;
      let prefix = self.$OBS_PREFIX;
      var obsClient = new ObsClient({
        access_key_id: ak,
        secret_access_key: sk,
        server: server,
        timeout: 60 * 5,
      });
      console.log(obsClient);
      // put objects
      obsClient
        .putObject({
          Bucket: bucket,
          Key: key,
          Metadata: { property: "property-value" },
          SourceFile: file,
        })
        .then(function (result) {
          console.log(result);
          if (result.CommonMsg.Status == 200) {
            // 上传成功后，关闭上传界面，打开修改名称弹框
            callback && callback(file, key);
            let obsUrl = prefix + key;
            let thumbSrc =
              obsUrl + "?x-image-process=image/resize,w_210,limit_0";
            console.log(thumbSrc);
            self.dataInfo.obsUrl = obsUrl;
            self.dataInfo.obsThumbUrl = thumbSrc;
            self.updatersource(self.dataInfo);
          }
        })
        .catch(function (err) {
          self.uploadLoading = false;
          self.$message.error(err);
        });
    },

    // 更新缩略图属性
    updatersource(data) {
      this.$http
        .post("/resource-info/update", data)
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success("截取封面成功");
          }
        })
        .catch((err) => {
          this.$message.error("截取封面失败");
        });
    },
    //将base64转换为blob
    dataURLtoBlob(dataurl, fileName) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, { type: mime });
      // return new Blob([u8arr], { type: mime })
    },
    //将blob转换为file
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date();
      theBlob.name = fileName;
      return theBlob;
      // let file = new window.File([theBlob], fileName, { type: theBlob.type });
      // return file;
    },
    handleDownLoad() {
      let ids = this.dataInfo.id;
      this.$http
        .post("/download/download", null, { params: { ids } })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            downloadModelV2(this.dataInfo.objectId, this.dataInfo.name);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
};
</script>

<style lang="less">

.model-view-page {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  iframe {
    display: block; /* iframes are inline by default */
    border: none; /* Reset default border */
    height: 100vh; /* Viewport-relative units */
    width: 100vw;
    background: #fff;
  }

  .model-detail-modal {
    // position: absolute;
    // left: 20px;
    // top: 20px;
    padding: 20px;
    border-radius: 4px;
    background-color: #f7f7f7;
    .info-wrapper {
      // flex: 1;
      min-width: 18vmax;
      max-width: 360px;
      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      // padding-left: 20px;
      .c-wrapper {
        padding: 10px 20px 20px 20px;
        background: #fafafa;
        border: 1px solid #d2d2d2;
        border-radius: 6px;
        margin-bottom: 15px;
        &.c-wrapper-tag {
          height: 115px;
          .el-tag {
            cursor: pointer;
          }
        }
      }
      .basic-wrapper {
        padding-bottom: 30px;
        ul {
          > li {
            margin-bottom: 5px;
            p {
              line-height: 22px;
            }
            font-size: 14px;
            .name {
              color: #5a5a5a;
            }
            .pointer {
              cursor: pointer;
            }
            .value {
              color: #3d3d3d;
              font-weight: 700;
              &.link-value {
                cursor: pointer;
                &:hover {
                  color: #0ca790;
                }
              }
            }
          }
        }
      }
      .colors-wrapper {
        padding: 16px 10px 10px 10px;
        .prop-color-card {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          .color-caard-item {
            width: 19%;
            height: 26px;
            margin-bottom: 6px;
            border-radius: 2px;
            cursor: pointer;
            transition: all ease 0.38s;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
      .user-wrapper {
        .name {
          color: #5a5a5a;
          line-height: 40px;
        }
        .value {
          display: flex;
          align-items: center;

          > span {
            margin-left: 10px;
            color: #3d3d3d;
            font-weight: 700;
          }

          .user-name {
            flex: 1;
            max-width: initial;
          }
        }
      }
      .intercept-wrapper {
        .name {
          color: #5a5a5a;
          line-height: 40px;
        }
        .cover {
          display: flex;
          justify-content: center;
        }
        .btnContainer {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 20px;
          .screenshot-btn {
            width: 150px;
            height: 40px;
            background: #8565f6;
            border-radius: 20px;
            color: #fff;
            &:hover {
              background: #673ff5;
            }
          }
        }
      }
      .save-workspace-btn {
        height: 40px;
        width: 100%;
        background: #8565f6;
        border-radius: 20px;
        color: #fff;
        &:hover {
          background: #673ff5;
        }
      }
      .download-wrapper {
        padding-top: 10px;
        text-align: center;
        display: flex;
        flex-flow: row nowrap;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;
        .download-btn,
        .screenshot-btn {
          margin-left: 0;
          margin-top: 4px;
          flex: 1;
          height: 40px;
          background: #8565f6;
          border-radius: 20px;
          color: #fff;
          &:hover {
            background: #673ff5;
          }
        }
      }
      .tag-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .el-tag {
          margin-right: 5px;
          margin-bottom: 5px;
          background-color: #fff;
          color: #3c3c3c;
          .el-tag__close {
            color: #3c3c3c;
          }
        }
      }
    }
  }
  #canvas {
    max-width: 100%;
    max-height: 100%;
  }
}
.reload-qty {
  position: relative;
  &:hover {
    .reload-mask-qty {
      display: block;
    }
  }
  .reload-mask-qty {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    .reload-container-qty {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 16px;
      cursor: pointer;
      .icon-qty {
        > img {
          width: 30px;
          height: 30px;
        }
      }
      .name-qty {
        margin-top: 10px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
}
</style>
